import { render, staticRenderFns } from "./Tournament.vue?vue&type=template&id=0d52e75d&scoped=true"
import script from "./Tournament.vue?vue&type=script&lang=js"
export * from "./Tournament.vue?vue&type=script&lang=js"
import style0 from "./Tournament.vue?vue&type=style&index=0&id=0d52e75d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d52e75d",
  null
  
)

export default component.exports